
import { defineComponent, defineAsyncComponent } from "vue";
import { PopoutsModule } from "@/store/modules/popouts";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Language from "@/components/settings-area/Language.vue";
const SetProfile = defineAsyncComponent(() => import("./SetProfile.vue"));
const AdditionalProfile = defineAsyncComponent(
  () => import("./AdditionalProfile.vue")
);
const ManageEmojis = defineAsyncComponent(
  () => import("@/components/settings-area/manage-emojis/ManageEmojis.vue")
);
const Thanks = defineAsyncComponent(() => import("./Thanks.vue"));

import Button from "@/components/CustomButton.vue";
import { MeModule } from "@/store/modules/me";
import { welcomeDone } from "@/services/userService";
export default defineComponent({
  name: "Welcome",
  components: {
    Button,
    SetProfile,
    LoadingScreen,
    AdditionalProfile,
    Thanks,
    ManageEmojis,
    Language,
  },
  props: {
    identity: { required: true, type: String },
  },
  data() {
    return {
      pages: [
        "Language",
        "SetProfile",
        "AdditionalProfile",
        "ManageEmojis",
        "Thanks",
      ],
      maxBeenToIndex: 0,
      currentPage: 0,
      nextClicked: false,
    };
  },
  computed: {
    connected() {
      return MeModule.connected;
    },
    currentPageName() {
      return this.pages[this.currentPage];
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage > this.maxBeenToIndex) {
        this.maxBeenToIndex = this.currentPage;
      }
    },
  },
  methods: {
    close() {
      welcomeDone();
      PopoutsModule.ClosePopout(this.identity);
    },
    onAction(goNext: boolean) {
      if (goNext) {
        this.currentPage++;
      }
      this.nextClicked = false;
    },
    backClick() {
      if (!this.currentPage) return;
      this.currentPage--;
    },
    nextClick() {
      if (this.nextClicked) return;
      this.nextClicked = true;
      const pageEl = this.$refs[this.currentPageName] as any;
      pageEl?.onNext();
    },
  },
});
