
import i18n from "@/i18n";
import languages from "@/locales/languages";
import emojiParser from "@/utils/emojiParser";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Language",
  data() {
    return {
      languages: languages,
      currentLang: localStorage["locale"] || "en",
    };
  },
  computed: {
    mappedLanguages(): {
      id: any;
      name: any;
      flagImage: any;
      translators: any[];
    }[] {
      return Object.keys(languages).map((key) => {
        return {
          ...languages[key],
          id: key,
          flagImage: emojiParser.replaceEmojis(languages[key].unicode),
        };
      });
    },
  },
  methods: {
    // used for Welcome.vue popout.
    onNext() {
      this.$emit("onAction", true);
    },
    changeLanguage(id: string) {
      this.currentLang = id;
      localStorage.setItem("locale", id);
      if (id === "en") {
        this.$i18n.locale = "en";
        return;
      }
      import(`@/locales/${id}.json`).then((messages) => {
        i18n.global.setLocaleMessage(id, messages.default);
        this.$i18n.locale = id;
      });
    },
  },
});
